<template>
<div>
  <v-dialog
    v-model="activo"
    width="700"
    :persistent="load"
    scrollable>
    <v-card>
      <v-card-title class="">
        <div style="font-size: 20px">
          Agregar Artículo
        </div>
        <v-spacer></v-spacer>
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table      
          class="cebra elevation-2 mt-2"
          :headers="headers"
          :search="search"
          :items="articulos"
          :loading="load"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options':[10, 15, 50, 100]}"
          dense
          >
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="3" xl="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template  v-slot:[`item.cantidad`]="{item}">
            <v-text-field-integer
              v-model="item.cantidad"
              v-bind:properties="{
                type: 'tel',
                'hide-details': true,
                outlined: true,
                dense: true
              }"
              @blur="defCantidad(item)"
            />
          </template>
          <template v-slot:[`item.accion`]="{item}">
            <v-btn 
              icon color="success" title="Agregar"
              @click="seleccionarArt(item)">
              <v-icon small>
                fas fa-check
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron datos
            </v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          color="secondary"
          :disabled="load"
          @click="activo = false"
        >Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { format_money } from '../../util/utils';
import SearchDataTableVue from '../util/SearchDataTable.vue';

export default {
  name: 'ModalElegirArticuloFC',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default: {}
    }
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    proveedor_codigo:{
      get(){
        return this.datos.proveedor_codigo;
      },
    },
    empresa_codigo:{
      get(){
        return this.datos.empresa_codigo;
      },
    },
  },
  data(){
    return{
      load: false,
      search: '',
      format_money: format_money,
      headers: [
        { text: 'Código', value: 'articulo_codigo' },
        { text: 'Descripción', value: 'articulo_nombre' },
        { text: 'Cantidad', value: 'cantidad', align: 'right' },
        { text: 'Precio s/ iva', value: 'precio_s_iva', align: 'right', formatter: format_money },
        { text: 'Seleccionar', value: 'accion', filtreable: false },
      ],
      articulos: [],
    }
  },
  created(){

  },
  methods:{
    async init(){
      this.load = true;
      this.$store.state.loading = true
      const res = await this.$store.dispatch('facturasCompra/obtenerArticulos',{
        empresa_codigo: this.empresa_codigo,
        proveedor_codigo: this.proveedor_codigo,
        todos: 0,
      })
      this.$store.state.loading = false;
      this.load = false;
      if(res.resultado == 1){
        this.articulos = res.articulos
        for(let i in this.articulos){
          this.articulos[i].precio = this.articulos[i].precio_s_iva;
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: res.msj,
          color: 'error',
        })
      }
    },
    seleccionarArt(item){
      const pos = this.articulos.indexOf(item)
      this.articulos[pos].cantidad = item.cantidad
      this.$emit('agregarNvo',this.articulos[pos], 1)
    },
    defCantidad(item){
      const pos = this.articulos.indexOf(item)
      this.articulos[pos].cantidad = item.cantidad
    }
  },
  components:{
    SearchDataTableVue
  },
  watch:{
    activo(val){
      if(val){
        this.init();
      }else{
        this.search = '';
        this.articulos = [];
      }
    }
  }
}
</script>

<style>

</style>