<template>
  <v-dialog
    v-model="activo"
    width="700"
    :persistent="load"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        {{ proveedor_nombre }}
        <v-btn right icon @click="activo = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selected"
          class="cebra elevation-2 mt-2"
          :headers="origen == 'recepcion'? headerRecepcion : (!agregados ? headerOrdCompraAgregados : headerOrdCompra)"
          :items="recepcionesOrdenes"
          :loading="load"
          :search="search"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
          dense
          :item-key="origen == 'recepcion'? 'recepcion_id' : 'orden_id'"
          :show-select="origen =='recepcion'? true : agregados"
          @toggle-select-all="selectAllToggle"
        >
          <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }" v-if="origen!='recepcion'">
            <v-simple-checkbox
              v-if="agregados"
              :value="isSelected"
              :readonly="item.disabled"
              :disabled="item.moneda_id != moneda_id"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`header.activa`]>
            <v-checkbox
              :key="key_c"
              v-model="selectTodas"
              v-if="!agregados && origen!='recepcion'"
              title="Seleccionar todas"
              class="ml-1"
              hide-details
            ></v-checkbox>
          </template>
          <template v-slot:[`item.activa`]="{ item }" v-if="!agregados && origen!='recepcion'">
            <v-checkbox
              class="ma-0 pb-1"
              :key="key_c"
              v-model="item.activa"
              hide-details
              @click="controlarMoneda(item)"
            ></v-checkbox>
          </template>
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="pa-2" no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="6" sm="3" md="4">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.total`]="{item}">
            {{/*origen != 'recepcion' ? format_money_round(item.total,4) : item */ format_money_round(item.total,4)  }}
          </template>
          
          <template v-slot:[`item.moneda_id`]="{item}">
            {{ item.moneda_id == 1 ? 'PESOS' : 'DOLAR' }}
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No se encontraron datos
            </v-alert>
          </template>
        </v-data-table>
        <!-- BOTONES -->
        <v-row class="d-flex justify-end mx-0 my-3">
          <SelectedCounterVue
            v-if="origen == 'recepcion' || agregados"
            :selected.sync="selected"
            :items.sync="recepcionesOrdenes"
            :custom="true"
            @criterio="select_all()"
          />
          <div v-else>
            <div
              v-show="selected.length > 0"
              class="body-2 pt-2 mx-1"
            >
              {{ selected.length }} fila{{ selected.length > 1 ? 's' : '' }} seleccionada{{ selected.length > 1 ? 's' : '' }} de {{ recepcionesOrdenes.length }}
              <template>
                <span v-if="selected.length != recepcionesOrdenes.length">
                  -
                  <v-hover v-slot="{ hover }">
                    <a
                      :class="hover ? 'text-decoration-underline' : ''"
                      @click="selectTodas = true;"
                    >
                      Seleccionar todas
                    </a>
                  </v-hover>
                </span>
                -
                <v-hover v-slot="{ hover }">
                  <a
                    class="orange--text"
                    :class="hover ? 'text-decoration-underline' : ''"
                    @click="selectTodas = false; desSelectAll()"
                  >
                    Limpiar selección
                  </a>
                </v-hover>
              </template>
            </div>
          </div>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="mr-2"
            color="info"
            :disabled="selected.length == 0"
            @click="activo = false; $emit('agregarSelected',selected);"
          >Seleccionar</v-btn>
          <v-btn
            class="mr-2"
            color="secondary"
            :disabled="load"
            @click="activo = false"
          >Volver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { format_money, format_money_round } from '../../util/utils';
import SelectedCounterVue from '../util/SelectedCounter.vue';
import SearchDataTableVue from '../util/SearchDataTable.vue';

export default {
  name: 'ModalObtRecepOrd',
  props:{
    value: Boolean,
    datos:{
      type: Object,
      default:{

      }
    }
  },
  data(){
    return{
      format_money: format_money,
      format_money_round:format_money_round,
      load: false,
      search: '',
      recepcionesOrdenes: [],
      headerRecepcion: [
        { text: 'Número', value: 'recepcion_id' }, 
        { text: 'Deposito', value: 'deposito', formatter: format_money }, 
        { text: 'Fecha', value: 'fecha' },
        { text: 'Emision-Número', value: 'documento' }
      ],
      headerOrdCompra: [
        { text: 'Orden Compra', value: 'orden_id' },
        { text: 'Estado', value: 'estado_nombre' }, 
        { text: 'Fecha', value: 'fecha' }, 
        { text: "Modena", value: 'moneda_id' },
        { text: 'Total', value: 'total', formatter: format_money}
      ],
      headerOrdCompraAgregados: [
        { text:'', value:'activa', filtreable: false, sorteable: false, aling: 'center', },
        { text: 'Orden Compra', value: 'orden_id' }, 
        { text: 'Estado', value: 'estado_nombre' }, 
        { text: 'Fecha', value: 'fecha' }, 
        { text: "Modena", value: 'moneda_id' },
        { text: 'Total', value: 'total', formatter: format_money}
      ],
      selected:[],
      tipoMonedaBand: null,
      selectTodas: false,
      key_c : 0,
    }
  },
  created(){
  },
  methods:{
    async init(){
      let path;
      let obj;
      let band = 0;
      //if(this.origen){
        if(this.origen == 'recepcion'){
          path = 'facturasCompra/obternerRecepcioneXproveedor'
          obj = {proveedor_codigo: this.proveedor_codigo, opcion: 0}
        }else{
          path = 'facturasCompra/obtenerOrdenesDeCompraDisponibles'
          obj = {proveedor_codigo: this.proveedor_codigo, opcion: 2, moneda_id: this.moneda_id}
        }
        this.$store.state.loading = true
        this.load = true;
        let peticion = await this.$store.dispatch(path,obj)
        this.load = false;
        this.$store.state.loading = false
        if (peticion.resultado == 1) {
          if(this.origen == 'recepcion'){
            this.recepcionesOrdenes = peticion.recepciones;
          }else{
            this.recepcionesOrdenes = peticion.ordenes;
            this.recepcionesOrdenes.forEach(elem => {
              elem.fecha = moment(elem.fecha).format('DD/MM/YYYY');
              elem.activa = false;//agrega para el check en false;
            });
          }
        }
        else{
          this.$store.dispatch('show_snackbar', {
            text: multiPeticion.msj,
            color: 'error',
          })
        }
      //}
    },
    select_all () {
      this.selected = []
      this.recepcionesOrdenes.forEach(elem => {
        if(elem.moneda_id == this.moneda_id){
          this.selected.push(elem)
        }
      })
    },
    select_all_recep () {
      this.selected = []
      this.recepcionesOrdenes.forEach(elem => {
        this.selected.push(elem)
      })
    },
    selectAllToggle(){
      if(this.origen != 'recepcion'){
        if(this.agregados){
          if(this.selected.length < this.recepcionesOrdenes.filter(elem => elem.moneda_id == this.moneda_id).length){
            this.select_all()
          }
          else{ this.selected = []}
        }else{
          this.controlarMonedaSelects();
        } 
      }else{
        if(this.selected.length < this.recepcionesOrdenes.length){
          this.select_all_recep()
        }
        else this.selected = []
      }
    },
    controlarMoneda(item){
      if(this.selected.length == 0){
        this.selected.push(item);
        this.tipoMonedaBand = item.moneda_id;
      }else{
        if(item.activa == false){
          this.selected = this.selected.filter(elem => elem.orden_id != item.orden_id);
        }else{
          if(item.moneda_id == this.tipoMonedaBand){
            this.selected.push(item);
          }else{
            item.activa = false;
            this.key_c++;
            return this.$store.dispatch('show_snackbar', {
              text: 'No puede seleccionar monedas de ordenes distintas.',
              color: 'orange'
            })
          }
        }
      }
    },
    agregarSelectAll(){
      this.tipoMonedaBand = this.recepcionesOrdenes[0].moneda_id;
      let ban = false;
      let i = 0;
      while(i < this.recepcionesOrdenes.length && !ban){
        if(this.recepcionesOrdenes[i].moneda_id != this.tipoMonedaBand){
          ban = true;
        }
        i++;
      }
      if(ban){
        this.selectTodas = false;
        this.$store.dispatch('show_snackbar', {
          text: 'No es posible seleccionar todas, Ordenes son distinta moneda.',
          color: 'orange'
        })
        return
      }
      //this.selectTodas = true;
      this.recepcionesOrdenes.forEach(elem => {
        elem.activa = true;
        this.selected.push(elem);
      })
    },
    desSelectAll(){
      this.recepcionesOrdenes.forEach(elem => {
        elem.activa = false;
      });
      this.selected = [];
    }
  },
  computed:{
    activo:{
      get(){
        return this.datos.activo;
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    origen:{
      get(){
        return this.datos.origen;
      }
    },
    proveedor_codigo:{
      get(){
        return this.datos.proveedor_codigo;
      }
    },
    proveedor_nombre:{
      get(){
        return this.datos.proveedor_nombre;
      }
    },
    moneda_id:{
      get(){
        return this.datos.moneda_id;
      }
    },
    agregados:{
      get(){
        return this.datos.agregados;
      }
    },
  },
  components:{
    SelectedCounterVue, SearchDataTableVue
  },
  watch:{
    activo(val){
      if(val){
        this.init();
      }else{
        this.selected = [];
        this.tipoMonedaBand = null;
        this.selectTodas = false;
        //this.desSelectAll();
      }
    },
    selectTodas: function(val){
      if(!val){ //si es false
        this.desSelectAll();
      }else{
        this.agregarSelectAll();
      }
      this.key_c++;
    },
    selected: function(){
      /*if(this.selected.length == 0){
        //this.selectTodas = false;
        this.desSelectAll();
      }*/
    }
  }
}
</script>

<style>

</style>